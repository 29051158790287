import React, { useContext } from 'react';
import cn from 'classnames';
import styles from './index.module.scss';

import { AuthContext } from 'context/AuthContext';
import returnRabbitLogo from 'assets/img/brand/rr-logo.svg';
import shipStationLogo from 'assets/img/brand/shipstation.svg';
import trackingRabbitLogo from 'assets/img/brand/tracking-rabbit-logo.png';

export default function Logo({ className = '' }) {
  const { appType } = useContext(AuthContext);

  const logo = appType.shipmentTracking ? (
    <img src={trackingRabbitLogo} alt="logo" width="215" />
  ) : (
    <div className={styles.logos}>
      <img src={shipStationLogo} alt="logo" height="20" />
      <div className={styles.divider} />
      <img src={returnRabbitLogo} alt="logo" height="32" />
    </div>
  );

  return <div className={cn(styles.container, className)}>{logo}</div>;
}
