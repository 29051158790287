import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Alert from './Alert';
import routes from '../../config/routes/auth';
import UnauthenticatedLayout from 'containers/UnauthenticatedLayout';

export default function DefaultLayout(props) {
  return (
    <UnauthenticatedLayout id="login-layout" renderHeader={() => <Alert />}>
      <Switch>
        {routes.map((route, idx) => {
          const Component = route.component;
          return Component ? (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              name={route.name}
              render={routeProps => <Component {...routeProps} {...props} />}
            />
          ) : null;
        })}
      </Switch>
    </UnauthenticatedLayout>
  );
}
