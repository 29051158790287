import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Connect } from './components';
import { AuthContext } from 'context/AuthContext';
import UnauthenticatedLayout from 'containers/UnauthenticatedLayout';

export default function ShipStationConnect() {
  const { appType } = useContext(AuthContext);

  if (!appType.retex) {
    return <Redirect to="/" />;
  }

  return (
    <UnauthenticatedLayout
      renderHelptext={() => (
        <p>
          <span>With the ShipStation Returns</span> free plan, you get a returns
          management solution with free unlimited returns and exchanges as well
          as steeply discounted shipping rates from USPS and UPS, along with
          best in class revenue retention capabilities.
        </p>
      )}
    >
      <Switch>
        <Route path="/ss-connect/:sellerId" component={Connect} />
        <Redirect to="/" />
      </Switch>
    </UnauthenticatedLayout>
  );
}
