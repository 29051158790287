import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { If } from 'utils';
import { getUrlParameter } from 'utils/methods';
import { FormWrapperOne, FormWrapperTwo } from './components';

export default class Login extends Component {
  state = {
    retailers: [],
    step: 1
  };

  reset = () => this.setState({ retailers: [], step: 1 });

  loggedIn = data => this.props.login(data);

  proceed = ({ retailers, email }) => {
    this.setState({ retailers, email, step: 2 });
  };

  render() {
    const { step, retailers, email } = this.state;
    const appInstalled = getUrlParameter(null, 'app_install');
    const storeOwnerEmail = getUrlParameter(null, 'email');
    return (
      <div id="login-row">
        <Card className="login-card">
          <CardBody className="p-0">
            <CardTitle className="text-center">Login to your account</CardTitle>
            <If test={appInstalled}>
              <div className="login__emailSent">
                Welcome to Return Rabbit! An email has been sent with your
                credentials{storeOwnerEmail ? ` on ` : ''}
                <strong>{storeOwnerEmail}</strong>. Please check your spam as
                well.
              </div>
            </If>
            <If test={step === 1}>
              <FormWrapperOne proceed={this.proceed} />
            </If>
            <If test={step === 2}>
              <FormWrapperTwo
                email={email}
                retailers={retailers}
                loggedIn={this.loggedIn}
              />
              <div className="text-right reset-link" onClick={this.reset}>
                Reset form
              </div>
            </If>
          </CardBody>
        </Card>
        <div className="text-right mt-2">
          <Link to="/reset-password" className="route-switch-link">
            Forgot your password?
          </Link>
        </div>
      </div>
    );
  }
}
