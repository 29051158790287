import React, { Component } from 'react';
import { Formik } from 'formik';
import { UncontrolledAlert } from 'reactstrap';

import { login as loginUser } from 'utils/services';
import { isValidEmail } from 'utils/validators';
import StepTwoForm from './StepTwoForm';

export default class FormWrapper extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.closeError = this.closeError.bind(this);
    this.state = {
      error: false,
      loading: false,
      retailers: props.retailers.map(val => ({ value: val, label: val }))
    };
    this.errorMessage = '';
    this.defaultValues = { email: props.email || '' };
  }

  handleSubmit(values) {
    this.errorMessage = '';
    this.setState({ loading: true, error: null });
    this.login(values);
  }

  login(values) {
    loginUser(
      values,
      payload => {
        this.setState({ loading: false });
        this.props.loggedIn(payload);
      },
      message => {
        this.errorMessage = message;
        this.setState({ loading: false, error: message !== null });
      }
    );
  }

  setError(msg) {
    this.errorMessage = msg;
    this.setState({ error: true });
  }

  handleChange() {
    this.setState({ error: false, isPasswordChange: false });
  }

  closeError() {
    this.setState({ error: false, isPasswordChange: false });
  }

  validate = values => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!isValidEmail(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password || values.password.length < 1) {
      errors.password = 'Required';
    }
    if (
      (!values.retailer_name || values.retailer_name.length < 1) &&
      this.state.retailers.length > 0
    ) {
      errors.retailer = 'Please choose a retailer';
    }
    return errors;
  };

  render() {
    const { retailers, error, loading } = this.state;
    return (
      <div>
        <UncontrolledAlert
          className="text-left"
          color="danger"
          isOpen={error}
          toggle={this.closeError}
        >
          <strong>Error!</strong> {this.errorMessage}
        </UncontrolledAlert>
        <Formik
          validateOnChange={false}
          validate={this.validate}
          initialValues={Object.assign({}, this.defaultValues)}
          onSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          render={formikProps => (
            <StepTwoForm {...formikProps} loading={loading} list={retailers} />
          )}
        />
      </div>
    );
  }
}
